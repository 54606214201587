@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Felix";
    font-weight: 400;
    src: url(./resources/fonts/Felix_Titling_Regular.ttf) format("ttf");
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
